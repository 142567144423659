<template>
  <div class="ml-5 mr-5 mb-5 mt-3">
    <b-card-group deck>
      <b-card no-body header="Need Help">
        <b-list-group flush>
          <b-list-group-item
            v-on:click="moveTo"
            v-bind:class="{ active: isSelected(0) }"
            class="list-group-item"
            >FAQ</b-list-group-item
          >

          <b-list-group-item
            v-on:click="onClickHandle"
            v-bind:class="{ active: isSelected(1) }"
            class="list-group-item"
            >Support Ticket</b-list-group-item
          >
        </b-list-group>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
export default {
  name: "need-help",
  data() {
    return {
      selected: 0,
      isLoading: false,
      orderNumber: "",
    };
  },

  methods: {
    isSelected(i) {
      return i === this.selected;
    },
    onClickSupportTicket() {
      this.$router.push({
        name: "need-help-support-ticket-orderId",
        params: { orderId: this.orderNumber },
      });
    },
    moveTo() {
      this.selected = 0;
      this.$router.push(`/need-help-faq/${this.orderNumber}`);
    },
    onClickHandle() {
      this.selected = 1;
      this.$router.push(`/need-help-support-ticket/${this.orderNumber}`);
    },
    onClickfaq() {
      this.$router.push({
        name: "need-help-faq-orderId",
        params: { orderId: this.orderNumber },
      });
    },
    // onClickCallSeller() {
    //   this.$router.push({
    //     name: "call-seller-orderId",
    //     params: { orderId: this.orderNumber },
    //   });
    // },
  },
  mounted() {
    this.orderNumber = this.$route.params.orderId;
    if (this.$route.path === `/need-help/${this.orderNumber}`) {
      this.onClickfaq();
    }
  },
};
</script>

<style scope>
.router-link-exact-active {
  color: #fd7e14;
}
.router-link-exact-active:hover {
  color: #fd7e14;
}
.list-group-item.active {
  background-color: #ff6a2e !important;
  color: white;
  font-weight: 500 !important;
}
</style>
