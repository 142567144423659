var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ml-5 mr-5 mb-5 mt-3"
  }, [_c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": "",
      "header": "Need Help"
    }
  }, [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', {
    staticClass: "list-group-item",
    class: {
      active: _vm.isSelected(0)
    },
    on: {
      "click": _vm.moveTo
    }
  }, [_vm._v("FAQ")]), _c('b-list-group-item', {
    staticClass: "list-group-item",
    class: {
      active: _vm.isSelected(1)
    },
    on: {
      "click": _vm.onClickHandle
    }
  }, [_vm._v("Support Ticket")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }